<template>
	<main>
		<el-dialog :visible="true" @close="close">
			<template slot="title">
				<div class="title">{{ query.id ? '编辑' : '创建' }}{{ title }}</div>
			</template>
			<div class="form-box">
				<el-form class="create-form" ref="formRef" :rules="rules" :model="form" label-width="100px">
					<el-form-item label="设备编号" prop="code" v-show="form.id">
						<el-input v-model="form.code" disabled/>
					</el-form-item>
					<el-form-item label="名称" prop="title">
						<el-input v-model="form.title"/>
					</el-form-item>
					<el-form-item label="图片" prop="image">
						<form-upload v-model="form.image" accept="image"/>
					</el-form-item>
					<el-form-item label="类型" prop="type">
						<el-select class="el-block" v-model="form.type">
							<el-option :value="item.id" v-for="(item,index) in column_option.type" :key="index" :label="item.title"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="设备分类" prop="category_id">
						<form-api v-model="form.category_id" :api="category.all"/>
					</el-form-item>
					<el-form-item label="规格" prop="specification">
						<el-input v-model="form.specification"/>
					</el-form-item>
					<el-form-item label="所属部门" prop="department_id">
						<form-api v-model="form.department_id" :api="department.all"/>
					</el-form-item>
					<el-form-item label="存放地点" prop="address">
						<el-input v-model="form.address"/>
					</el-form-item>
					<el-form-item label="使用人" prop="user">
						<el-input v-model="form.user"/>
					</el-form-item>
					<el-form-item label="维保部门" prop="check_department_id">
						<form-api v-model="form.check_department_id" :api="department.all"/>
					</el-form-item>
					<div class="form-flex">
						<el-form-item label="厂商" prop="producer">
							<el-input v-model="form.producer"/>
						</el-form-item>
						<el-form-item label="出厂编号" prop="producer_code">
							<el-input v-model="form.producer_code"/>
						</el-form-item>
					</div>
					<div class="form-flex">
						<el-form-item label="入厂时间" prop="buy_time">
							<form-date-picker v-model="form.buy_time" type="date"/>
						</el-form-item>
						<el-form-item label="备注" prop="remark">
							<el-input v-model="form.remark"/>
						</el-form-item>
					</div>
					<div class="form-flex">
						<el-form-item label="状态" prop="status" v-if="query.id">
							<el-radio-group v-model="form.status" disabled>
								<el-radio v-for="(item,index) in column_option.status" :key="index" :label="item.id">{{ item.title }}</el-radio>
							</el-radio-group>
						</el-form-item>
						<el-form-item v-show="form.id" label="创建时间" prop="create_time">
							<form-date-picker v-model="form.create_time" type="datetime"/>
						</el-form-item>
					</div>
					<div class="form-flex">
						<el-form-item v-show="form.status === 2" label="报废原因" prop="drop_remark">
							<el-input v-model="form.drop_remark"/>
						</el-form-item>
						<el-form-item v-show="form.status === 2" label="报废时间" prop="drop_time">
							<form-date-picker v-model="form.drop_time" type="datetime"/>
						</el-form-item>
					</div>
				</el-form>
			</div>
			<div slot="footer" class="dialog-footer">
				<el-button @click="close">取 消</el-button>
				<el-button type="primary" :loadin="submit_loading" @click="submit">保 存</el-button>
			</div>
		</el-dialog>
	</main>
</template>

<script>
import {category, good as api, department} from "@/api/admin";
import {MixinCreate} from "@/service/admin/mixinCreate";

export default {
	mixins: [MixinCreate],
	data() {
		return {
			api,
		}
	},
	computed: {
		department() {
			return department
		},
		category() {
			return category
		},
		rules() {
			return {
				type: [
					{required: true, message: "请选择类型"}
				],
				category_id: [
					{required: true, message: "请选择设备分类"}
				],
				title: [
					{required: true, message: "请输入名称"}
				],
				specification: [
					{required: true, message: "请输入规格"}
				],
				department_id: [
					{required: true, message: "请选择所属部门"}
				],
				user: [
					{required: true, message: "请输入使用人"}
				],
				check_department_id: [
					{required: true, message: "请选择维保部门"}
				]
			}
		}
	},
}
</script>
