<template>
	<main>
		<el-dialog :visible="true" @close="close">
			<template slot="title">
				<div class="title">{{ title }}</div>
			</template>
			<div class="el-content">
				<table-search v-model="search_data" :column="column" @clean="clean" @search="search"/>
				<div class="action-box">
					<div class="action">
						<template v-if="import_visible">
							<el-button class="el-icon-download" @click="templateFile">下载导入模板</el-button>
							<el-button class="upload-btn el-icon-upload" :loading="upload_loading">批量导入
								<input type="file" id="file" @change="uploadFile" accept=".xlsx">
							</el-button>
						</template>
					</div>
					<div class="other">
						<table-column :column="column" @change="changeColumn"/>
						<el-button v-if="export_visible" class="el-icon-download" @click="exportAll" :loading="export_loading" title="导出"></el-button>
					</div>
				</div>
				<div class="table-box">
					<table-table :table_loading="table_loading" :table_data="table_data" :column_visible="column_visible" :column="column" @selection="selection" @sortChange="sortChange" @changeSort="changeSort">
						<template #column="{scope,item}"></template>
					</table-table>
				</div>
				<div class="page-box">
					<el-pagination @size-change="sizeChange" @current-change="currentChange" :current-page="page_info.page" :page-sizes="[10, 15, 20, 50,100]" :page-size="page_info.limit" layout="total,sizes,prev,pager,next,jumper" :total="page_info.total" hide-on-single-page background></el-pagination>
				</div>
			</div>
			<div slot="footer" class="dialog-footer">
				<el-button @click="close">关闭</el-button>
			</div>
		</el-dialog>
		<table-import v-if="import_visible" ref="importRef" :import_field="import_field" @saveAll="saveAll"/>
	</main>
</template>
<script>
import {MixinIndex} from "@/service/admin/mixinIndex";
import { good,good_user as api} from "@/api/admin";

export default {
	mixins: [MixinIndex],
	props:{
		query:{
			type:Object,
			default:false,
		}
	},
	data() {
		return {
			api,
			title: "使用记录",
			column_option: {},

			export_visible:true,
		}
	},
	computed: {
		export_field(){
			return [
				{label: "设备名称", prop: "good.title"},
				{label: "设备编号", prop: "good.code"},
				{label: "使用人", prop: "user"},
				{label: "开始时间", prop: "create_time", type: "datetime"},
				{label: "结束时间", prop: "end_time", type: "datetime"},
			]
		},
		column() {
			return [
				{label: "ID", prop: "id",  sortable: true},
				{label: "设备名称", prop: "good.title", type:'api',option:{api:good.all,field:'good_id'}},
				{label: "设备编号", prop: "good.code"},
				{label: "使用人", prop: "user"},
				{label: "开始时间", prop: "create_time", type: 'datetime', sortable: true},
				{label: "结束时间", prop: "end_time", type: 'datetime', sortable: true},
			]
		}
	},
	created() {
		this.init_data = {
			good_id:this.query.id
		}
	},
	methods:{
		close(){
			this.$emit('close')
		}
	}
}
</script>
<style lang="scss" scoped>
.el-content{min-height: auto;}
</style>
