<template>
	<main>
		<div class="el-content">
			<table-search v-model="search_data" :column="column" @clean="clean" @search="search"/>
			<div class="action-box">
				<div class="action">
					<el-button class="el-icon-refresh" @click="all"></el-button>
					<el-button class="el-icon-plus" @click="create">创建</el-button>
					<el-popconfirm title="确定要删除选中的数据吗？" @confirm="deleteChoose">
						<template #reference>
							<el-button icon="el-icon-delete">删除</el-button>
						</template>
					</el-popconfirm>
					<template v-if="import_visible">
						<el-button class="el-icon-download" @click="templateFile">下载导入模板</el-button>
						<el-button class="upload-btn el-icon-upload" :loading="upload_loading">批量导入
							<input type="file" id="file" @change="uploadFile" accept=".xlsx">
						</el-button>
					</template>
				</div>
				<div class="other">
					<table-column :column="column" @change="changeColumn"/>
					<el-button v-if="export_visible" class="el-icon-download" @click="exportAll" :loading="export_loading" title="导出"></el-button>
					<el-button class="el-icon-c-scale-to-original" title="批量下载二维码" :loading="qrcode_loading" @click="downloadQrcode"></el-button>
				</div>
			</div>
			<div class="table-box">
				<table-table :table_loading="table_loading" :table_data="table_data" :column_visible="column_visible" :column="column" @selection="selection" @sortChange="sortChange" @changeSort="changeSort">
					<template #action="{scope}">
						<div class="el-link-group">
							<el-link type="primary" @click="qrcode(scope.row)">二维码</el-link>
							<el-link type="primary" @click="log(scope.row)">记录</el-link>
							<el-link type="primary" @click="editor(scope.row)">编辑</el-link>
							<el-link type="primary" v-if="scope.row.status === 1" @click="drop(scope.row)">报废</el-link>
							<el-popconfirm title="确定要删除该数据吗？" @confirm="deleted(scope.row)">
								<template #reference>
									<el-link type="primary">删除</el-link>
								</template>
							</el-popconfirm>
						</div>
					</template>
				</table-table>
			</div>
			<div class="page-box">
				<el-pagination @size-change="sizeChange" @current-change="currentChange" :current-page="page_info.page" :page-sizes="[10, 15, 20, 50,100]" :page-size="page_info.limit" layout="total,sizes,prev,pager,next,jumper" :total="page_info.total" hide-on-single-page background></el-pagination>
			</div>
		</div>
		<table-import v-if="import_visible" ref="importRef" :import_field="import_field" @saveAll="saveAll"/>
		<components-create v-if="create_visible" ref="createRef" :title="title" :column_option="column_option" @success="all" @close="closeCreate"/>
		<components-log v-if="log_visible" :query="log_query" ref="logRef" @close="closeLog"/>
		<div id="konva-container" style="display: none"></div>
	</main>
</template>
<script>
import ComponentsCreate from "./Create.vue"
import ComponentsLog from "./Log.vue"
import {MixinIndex} from "@/service/admin/mixinIndex";
import {category, department, good as api} from "@/api/admin";
import QRCode from "qrcode";
import JSZip from "jszip";
import Konva from "konva";

export default {
	components: {ComponentsCreate, ComponentsLog},
	mixins: [MixinIndex],
	data() {
		return {
			api,
			title: "设备",
			column_option: {
				type: [{id: 1, title: "固定资产"}, {id: 2, title: "其他资产"}],
				status: [{id: 1, title: "正常"}, {id: 2, title: "报废"}],
			},
			import_visible: true,
			export_visible: true,

			log_visible: false,
			log_query: {},

			qrcode_loading: false,
		}
	},
	computed: {
		import_field() {
			return [
				{label: "名称", prop: "title", require: true},
				{label: "类型", prop: "type", require: true},
				{label: "设备分类", prop: "category", require: true},
				{label: "规格", prop: "specification", require: true},
				{label: "所属部门", prop: "department", require: true},
				{label: "存放地点", prop: "address"},
				{label: "使用人", prop: "user", require: true},
				{label: "维保部门", prop: "check_department", require: true},
				{label: "厂商", prop: "producer"},
				{label: "出厂编号", prop: "producer_code"},
				{label: "入厂时间(格式：2023-01-01)", prop: "buy_time"},
				{label: "备注", prop: "remark"},
			]
		},
		export_field() {
			return [
				{label: "设备编号", prop: "code"},
				{label: "名称", prop: "title"},
				{label: "类型", prop: "type", type: "option", option: this.column_option.type},
				{label: "设备分类", prop: "category.title"},
				{label: "规格", prop: "specification"},
				{label: "所属部门", prop: "department.title"},
				{label: "存放地点", prop: "address"},
				{label: "使用人", prop: "user"},
				{label: "维保部门", prop: "check_department.title"},
				{label: "厂商", prop: "producer"},
				{label: "出厂编号", prop: "producer_code"},
				{label: "入厂时间", prop: "buy_time", type: "date"},
				{label: "状态", prop: "status", type: "option", option: this.column_option.status},
				{label: "备注", prop: "remark"},
			]
		},
		column() {
			return [
				{label: "ID", prop: "id", sortable: true},
				{label: "设备编号", prop: "code", search: true},
				{label: "名称", prop: "title", search: true},
				{label: "图片", prop: "image", type: "image"},
				{label: "类型", prop: "type", type: "option", search: true, option: this.column_option.type},
				{label: "设备分类", prop: "category.title", search: true, type: 'api', option: {api: category.all, field: 'category_id'}},
				{label: "规格", prop: "specification"},
				{label: "所属部门", prop: "department.title", search: true, type: 'api', option: {api: department.all, field: 'department_id'}},
				{label: "存放地点", prop: "address"},
				{label: "使用人", prop: "user"},
				{label: "维保部门", prop: "check_department.title", search: true, type: 'api', option: {api: department.all, field: 'check_department_id'}},
				{label: "厂商", prop: "producer"},
				{label: "出厂编号", prop: "producer_code"},
				{label: "入厂时间", prop: "buy_time", type: 'date'},
				{label: "备注", prop: "remark"},
				{label: "状态", prop: "status", type: "option", search: true, option: this.column_option.status},
				{label: "创建时间", prop: "create_time", type: 'datetime', sortable: true},
				{label: "操作", prop: "action", width: 100, fixed: 'right', type: 'action'},
			]
		}
	},
	methods: {
		downloadQrcode() {
			if (this.qrcode_loading) {
				return false;
			}
			if (this.selection_list.length <= 0) {
				this.$message.error('请选择需要下载二维码的数据');
				return false;
			}
			this.qrcode_loading = true;
			const zip = new JSZip();
			const folder = zip.folder("设备二维码");
			const list = this.selection_list;
			const task = [];
			list.map(async (item, index) => {
				task.push(this.createQrcode(item))
			})
			Promise.all(task).then((res) => {
				res.map((item, index) => {
					if (item.qrcode) {
						const qrcode = item.qrcode.replace('data:image/png;base64,', '');
						folder.file(`${item.title}-${item.code}.png`, qrcode, {base64: true});
					}
				})
				zip.generateAsync({type: "base64"}).then((content) => {
					window.location.href = "data:application/zip;base64," + content;
				}).finally(() => {
					this.qrcode_loading = false;
				})
			})
		},
		qrcode(row) {
			this.createQrcode(row).then((res) => {
				if (!res.qrcode) {
					this.$message.error('创建二维码失败');
					return false;
				}
				const element = document.createElement('a');
				element.href = res.qrcode;
				element.title = row.title;
				element.download = row.title;
				element.click();
				element.remove();
			})
		},
		createQrcodeBak(item) {
			return new Promise((resolve, reject) => {
				QRCode.toDataURL(item.code, {
					margin: 0,
					width: 500,
					color: {
						light: "#FFFF0000"
					}
				}).then(async (res) => {
					const image = await this.createImage(res, item)
					resolve({
						qrcode: image,
						title: item.title,
						code: item.code
					})
				}).catch((error) => {
					resolve({
						qrcode: '',
						title: item.title,
						code: item.code
					})
				})
			})
		},
		createQrcode(item) {
			return new Promise((resolve, reject) => {
				api.qrcode({
					id: item.id
				}).then(async (res) => {
					const image = await this.createImage(res.file, item)
					resolve({
						qrcode: image,
						title: item.title,
						code: item.code
					})
				}).catch((error) => {
					resolve({
						qrcode: '',
						title: item.title,
						code: item.code
					})
				})
			})
		},
		async createImage(qrcode, item) {
			const stage = new Konva.Stage({
				container: 'konva-container',
				width: 1045,
				height: 600
			});
			const layer = new Konva.Layer();

			var bg = new Konva.Rect({
				x: 0,
				y: 0,
				width: stage.width(),
				height: stage.height(),
				fill: '#FFFF0000',
			});
			layer.add(bg);

			const imageObj = await this.loadImage(qrcode);
			const circle = new Konva.Image({
				x: 30,
				y: 15,
				image: imageObj,
				width: 467,
				height: 500
			});
			layer.add(circle);

			const code = new Konva.Text({
				x: 15,
				y: 535,
				width: 500,
				text: item.code,
				fontSize: 50,
				align: 'center',
				verticalAlign: 'middle',
				fontStyle: 'bold',
			})
			layer.add(code);

			const border = new Konva.Rect({
				x: 530,
				y: 15,
				width: 500,
				height: 500,
				stroke: 'black',
				strokeWidth: 5
			})
			const line1 = new Konva.Line({
				points: [530, 182, 1030, 182],
				stroke: '#000',
				strokeWidth: 5,
			});
			const line2 = new Konva.Line({
				points: [530, 347, 1030, 347],
				stroke: '#000',
				strokeWidth: 5,
			});
			layer.add(border);
			layer.add(line1);
			layer.add(line2);

			const title = new Konva.Text({
				x: 530,
				y: 20,
				width: 500,
				height: 162,
				text: item.title,
				fontSize: 50,
				lineHeight: 1.1,
				align: 'center',
				verticalAlign: 'middle',
				fontStyle: 'bold'
			})
			layer.add(title);
			const address = new Konva.Text({
				x: 530,
				y: 187,
				width: 500,
				height: 162,
				text: item.address,
				fontSize: 40,
				lineHeight: 1.1,
				align: 'center',
				verticalAlign: 'middle',
			})
			layer.add(address);
			const user = new Konva.Text({
				x: 530,
				y: 352,
				width: 500,
				height: 162,
				text: '负责人: ' + item.user,
				fontSize: 40,
				lineHeight: 1.1,
				align: 'center',
				verticalAlign: 'middle',
			})
			layer.add(user);

			stage.add(layer);
			layer.draw();
			return stage.toDataURL()
		},
		loadImage(src) {
			return new Promise((resolve, reject) => {
				Konva.Image.fromURL(src, (img) => {
					resolve(img.image())
				})
			})
		},
		log(row) {
			this.log_query = row;
			this.log_visible = true;
		},
		closeLog() {
			this.log_visible = false;
		},
		drop(row) {
			this.$prompt('请输入报废原因', '报废').then(({value}) => {
				if (!value) {
					this.$message.error('请输入报废原因');
					return false;
				}
				api.drop({
					id: row.id,
					message: value
				}).then((res) => {
					this.$message.success('操作成功');
					this.all();
				})
			})
		}
	}
}
</script>
